// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Icon, useTheme } from "@merit/frontend-components";
import { Datagrid, DatagridBody } from "../../components";
import { EllipsisText } from "../../components/EllipsisText";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../../components/Spacer";
import { View } from "react-native";
import { fieldHasCompletenessFailureByName } from "../../utils/fieldHasCompletenessError";
import { getDateTimeString } from "../../utils/time";
import { useAppConstantsStore } from "../../stores";
import { useGetContainerValueByBaseFieldName } from "../../utils/getContainerFieldValue";
import React, { useCallback } from "react";
import type { ContainerWithTemplateType } from "./Approvals";
import type { DatagridColumn } from "../../components/Datagrid/types";
import type { OPTestProps } from "../../types/TestProps";
import type { OrgsGet200ResponseContainersInner } from "../../gen/org-portal";

type Props = {
  readonly merits: readonly OrgsGet200ResponseContainersInner[];
  readonly onRemoveContainer: (containerID: string) => void;
  readonly onApproveContainer: (containerID: string) => Promise<void>;
  readonly onViewContainer: (container: ContainerWithTemplateType) => void;
};

const { Some } = Helpers;
const SCREEN_NAME = "Approvals";

export const Merits = ({
  merits,
  onApproveContainer,
  onRemoveContainer,
  onViewContainer,
}: Props) => {
  const { theme } = useTheme();
  const { meritFieldNames } = useAppConstantsStore();
  const { getContainerValueByBaseFieldName } = useGetContainerValueByBaseFieldName();

  const getTestProps = useCallback(
    (testProps: OPTestProps | undefined) =>
      Some(testProps)
        ? {
            ...testProps,
            elementName: `${testProps.elementName}ErrorIcon`,
          }
        : testProps,
    []
  );

  const columns: readonly DatagridColumn<OrgsGet200ResponseContainersInner>[] = [
    {
      key: "firstName",
      label: "First name",
      renderer: (container, testProps) => (
        <View style={{ alignItems: "center", flex: 1, flexDirection: "row" }}>
          <EllipsisText
            testProps={testProps}
            text={getContainerValueByBaseFieldName({
              container,
              fieldName: "firstName",
              templateType: "merit",
            })}
          />
          <HorizontalSpacer size={theme.spacing.xl} />
          {fieldHasCompletenessFailureByName(container, meritFieldNames.firstName) && (
            <Icon name="warningMediumCritical" testProps={getTestProps(testProps)} />
          )}
        </View>
      ),
      size: 120,
    },
    {
      key: "lastName",
      label: "Last name",
      renderer: (container, testProps) => (
        <View style={{ alignItems: "center", flex: 1, flexDirection: "row" }}>
          <EllipsisText
            testProps={testProps}
            text={getContainerValueByBaseFieldName({
              container,
              fieldName: "lastName",
              templateType: "merit",
            })}
          />
          <HorizontalSpacer size={theme.spacing.xl} />
          {fieldHasCompletenessFailureByName(container, meritFieldNames.lastName) && (
            <Icon name="warningMediumCritical" testProps={getTestProps(testProps)} />
          )}
        </View>
      ),
      size: 120,
    },
    {
      key: "email",
      label: "Email",
      renderer: (container, testProps) => (
        <View style={{ alignItems: "center", flex: 1, flexDirection: "row" }}>
          <EllipsisText
            testProps={testProps}
            text={getContainerValueByBaseFieldName({
              container,
              fieldName: "email",
              templateType: "merit",
            })}
          />

          <HorizontalSpacer size={theme.spacing.xl} />
          {fieldHasCompletenessFailureByName(container, meritFieldNames.email) && (
            <Icon name="warningMediumCritical" testProps={getTestProps(testProps)} />
          )}
        </View>
      ),
      size: "flex",
    },
    {
      key: "type",
      label: "Type",
      renderer: (container, testProps) => (
        <Body numberOfLines={1} testProps={testProps}>
          Merit
        </Body>
      ),
      size: 128,
    },
    {
      key: "name",
      label: "Merit template",
      renderer: (container, testProps) => (
        <EllipsisText testProps={testProps} text={container.name ?? "--"} />
      ),
      size: 150,
    },
    {
      key: "createdAt",
      label: "Created at",
      renderer: (container, testProps) => (
        <Body numberOfLines={1} testProps={testProps}>
          {Some(container.createdAt) ? getDateTimeString(container.createdAt) : "--"}
        </Body>
      ),
      size: 200,
    },
    {
      key: "actions",
      label: "Actions",
      // eslint-disable-next-line react/no-multi-comp
      renderer: (item, testProps) => (
        <View style={{ flexDirection: "row" }}>
          <View style={{ width: 80 }}>
            <Button
              onPress={() => {
                onViewContainer({ ...item, templateType: "Merit" });
              }}
              size="small"
              testProps={
                Some(testProps)
                  ? {
                      ...testProps,
                      elementName: `${testProps.elementName}ViewButton`,
                    }
                  : testProps
              }
              text="View"
              type="secondary"
            />
          </View>
          <HorizontalSpacer />
          <Button
            iconLeft="closeSmallDefault"
            onPress={() => {
              onRemoveContainer(item.id);
            }}
            shape="square"
            size="small"
            testProps={
              Some(testProps)
                ? {
                    ...testProps,
                    elementName: `${testProps.elementName}RejectButton`,
                  }
                : testProps
            }
            type="destructive"
          />
          <HorizontalSpacer />
          <View style={{ width: 130 }}>
            <Button
              disabled={item.completed === false}
              iconLeft={
                item.completed === false ? "checkmarkSmallDisabled" : "checkmarkSmallAction"
              }
              onPress={() => {
                onApproveContainer(item.id);
              }}
              size="small"
              testProps={
                Some(testProps)
                  ? {
                      ...testProps,
                      elementName: `${testProps.elementName}ApproveButton`,
                    }
                  : testProps
              }
              text="Approve"
            />
          </View>
        </View>
      ),
      size: 300,
    },
  ];

  return (
    <Datagrid
      columns={columns}
      testProps={{
        elementName: "meritsListView",
        screenName: SCREEN_NAME,
      }}
    >
      <DatagridBody
        columns={columns}
        data={merits}
        testProps={{
          elementName: "meritsListView",
          screenName: SCREEN_NAME,
        }}
        testPropsElementIdKey="id"
      />
    </Datagrid>
  );
};
