// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body, Icon, useTheme } from "@merit/frontend-components";
import { Breadcrumbs } from "@src/components/NewNav/Breadcrumbs";
import { CONTENT_WIDTH, HEADER_HEIGHT } from "@src/components/NewNav/constants";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "@src/components";
import { LoggedInHeader } from "../components/LoggedInHeader";
import { NewNav } from "@src/components/NewNav";
import { SafeAreaView, StyleSheet, TouchableOpacity, View } from "react-native";
import { useAcceptedFolioStore } from "../stores/acceptedFolioStore";
import { useApi } from "../api/api";
import { useAppConstantsStore, useConfigurationStore } from "../stores";
import { useAuthStore } from "../stores/authStore";
import { useNavigation } from "@react-navigation/native";
import React, { useEffect } from "react";
import type { BreadcrumbProps } from "@src/components/NewNav/Breadcrumbs";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { OPTestProps } from "../types/TestProps";
import type { OrgsGet200Response } from "../gen/org-portal";
import type { ReactNode } from "react";
import type { RouteParams } from "@src/Router";

type Props = {
  readonly children: ReactNode;
  readonly headerRightElements?: ReactNode;
  readonly title: string;
  readonly tabs?: ReactNode;
  readonly testProps?: OPTestProps;
  readonly breadcrumbs?: readonly BreadcrumbProps[];
};

const { Some } = Helpers;

export const NewDefaultLayout = ({
  breadcrumbs,
  children,
  headerRightElements,
  tabs,
  testProps,
  title,
}: Props) => {
  const { theme } = useTheme();
  const appConstants = useAppConstantsStore();
  const configStore = useConfigurationStore();
  const { setUserAcceptedAccountFolio, userAcceptedAccountFolio } = useAcceptedFolioStore();
  const { selectedOrgId } = useAuthStore();
  const { api } = useApi();
  const disableNav = !userAcceptedAccountFolio;
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams>>();

  const styles = StyleSheet.create({
    breadcrumbContainer: {
      flexDirection: "row",
      justifyContent: "flex-start",
      margin: "auto",
      width: CONTENT_WIDTH,
    },
    container: {
      backgroundColor: theme.colors.background.white,
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    disabledWarning: {
      backgroundColor: "#B8E3CF", // jade100
      borderBottomWidth: 1,
      borderColor: theme.colors.border.subdued,
      flexDirection: "row",
      justifyContent: "center",
      padding: 16,
      width: "100%",
    },
    headerContainer: {
      backgroundColor: theme.colors.background.white,
      borderBottomColor: theme.colors.border.subdued,
      borderBottomWidth: 1,
      justifyContent: "space-between",
    },
    mainContainer: {
      flex: 1,
      margin: "auto",
      width: CONTENT_WIDTH,
    },
    navContainer: {
      alignItems: "flex-end",
      borderBottomWidth: 1,
      borderColor: theme.colors.border.subdued,
      flexDirection: "row",
      height: HEADER_HEIGHT,
      justifyContent: "center",
      marginTop: 8,
      width: "100%",
    },
  });

  useEffect(() => {
    const TWO_MINUTES_MS = 1000 * 60 * 2;
    const checkFolioAcceptanceState = () => {
      if (
        userAcceptedAccountFolio ||
        selectedOrgId === null ||
        configStore.configuration === undefined
      ) {
        return;
      }

      api
        .getContainers({
          limit: appConstants.folioInboxLimit,
          orgID: selectedOrgId,
          recipientID: selectedOrgId,
          state: "pending",
          templateID: configStore.configuration.accountFolioTemplateUUID,
          templateType: "Folio",
        })
        .then((value: OrgsGet200Response) => {
          const numContainers = value.containers?.length ?? 0;
          setUserAcceptedAccountFolio(numContainers === 0);
          if (numContainers > 0) {
            // if we have any account/org folios that are not accepted, schedule this function to run again in ~2 minutes.
            setTimeout(checkFolioAcceptanceState, TWO_MINUTES_MS);
          }
        });
    };

    checkFolioAcceptanceState();
  }, [
    setUserAcceptedAccountFolio,
    userAcceptedAccountFolio,
    configStore,
    selectedOrgId,
    appConstants.folioInboxLimit,
    api,
  ]);

  return (
    <View style={{ flex: 1 }}>
      <SafeAreaView style={styles.container}>
        <View style={styles.navContainer}>
          <NewNav disableNav={disableNav} />
        </View>

        {disableNav && (
          <View style={styles.disabledWarning}>
            <TouchableOpacity
              onPress={() => {
                navigation.navigate("OrgSettings", { initialTab: "connectedOrganizations" });
              }}
              style={{ flexDirection: "row" }}
            >
              <Body>Welcome to Merit! Accept your account folio to get started.</Body>
              <HorizontalSpacer size={16} />
              <Icon name="arrowForwardMediumAction" />
            </TouchableOpacity>
          </View>
        )}

        {breadcrumbs !== undefined && (
          <View style={styles.breadcrumbContainer}>
            <Breadcrumbs items={breadcrumbs} />
          </View>
        )}

        <View style={styles.mainContainer}>
          <View style={styles.headerContainer}>
            <LoggedInHeader
              rightElements={headerRightElements}
              testProps={testProps}
              title={title}
            />
            {Some(tabs) ? (
              <View style={{ paddingHorizontal: 32 }}>{tabs}</View>
            ) : (
              <VerticalSpacer size={24} />
            )}
          </View>
          <View style={{ flex: 1 }}>{children}</View>
        </View>
      </SafeAreaView>
    </View>
  );
};
