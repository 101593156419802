// Copyright 2022 Merit International Inc. All Rights Reserved

import { AccessibilityWidget } from "@merit/frontend-components";
import { ErrorBoundary } from "./utils/ErrorBoundary";
import { Navigator } from "./Router";

export const App = () => (
  <ErrorBoundary>
    <>
      <AccessibilityWidget />
      <Navigator />
    </>
  </ErrorBoundary>
);
