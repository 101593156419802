// Copyright 2024 Merit International Inc. All Rights Reserved.

import { create } from "zustand";

/*
  Stores a mapping of org logo URLs by id
*/

export type OrgLogoState = {
  readonly orgLogos: Record<string, string | undefined>;
  readonly setOrgLogo: (orgId: string, newOrgLogo: string) => void;
  readonly getOrgLogo: (orgId: string) => string | undefined;
};

export const useOrgLogoStore = create<OrgLogoState>()((set, get) => ({
  getOrgLogo: (orgId: string) => {
    if (orgId in get().orgLogos) {
      return get().orgLogos[orgId];
    }

    return undefined;
  },
  orgLogos: {},
  setOrgLogo(orgId: string, newOrgLogo: string) {
    set(state => ({ orgLogos: { ...state.orgLogos, [orgId]: newOrgLogo } }));
  },
}));
