// Copyright 2024 Merit International Inc. All Rights Reserved

import { Button } from "@merit/frontend-components";
import { PreLoginFooter } from "@src/layouts/PreLoginFooter";
import { PreLoginLayout } from "../layouts/PreLoginLayout";
import { Spin } from "../components";
import { View } from "react-native";
import { useApi } from "../api/api";
import { useServerErrorHandler } from "../utils/useServerErrorHandler";
import React, { useState } from "react";

const SCREEN_NAME = "Landing";

export const LandingScreen = () => {
  const { api } = useApi();

  const { errorHandler } = useServerErrorHandler();
  const [isLoading, setIsLoading] = useState(false);

  const handleLoginButtonPress = async () => {
    const host = window.location.host;

    try {
      setIsLoading(true);
      const { link, state } = await api.getLoginLink({ host });

      // eslint-disable-next-line functional/immutable-data
      document.cookie = `state=${state}`;

      // eslint-disable-next-line require-atomic-updates,functional/immutable-data
      window.location = link as unknown as Location;
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PreLoginLayout>
      <View style={{ flex: 1 }}>
        <View style={{ alignItems: "center", flex: 1, justifyContent: "center" }}>
          {isLoading ? (
            <Spin />
          ) : (
            <Button
              onPress={() => {
                handleLoginButtonPress();
              }}
              testProps={{ elementName: "loginButton", screenName: SCREEN_NAME }}
              text="Login"
            />
          )}
        </View>
      </View>
      <PreLoginFooter />
    </PreLoginLayout>
  );
};
