// Copyright 2023 Merit International Inc. All Rights Reserved

import { Button, Heading, useTheme } from "@merit/frontend-components";
import { Drawer } from "../../components/Drawer";
import { Helpers } from "@merit/frontend-utils";
import { Pagination, Spin, Tabs, VerticalSpacer } from "../../components";
import { PoliciesTable } from "./PoliciesTable";
import { PolicyDetails } from "./PolicyDetails";
import { ScrollView, StyleSheet, View } from "react-native";
import { SearchForm } from "@src/components/SearchForm/SearchForm";
import { SharedPolicies } from "./SharedPolicies";
import { useAlertStore, useAppConstantsStore } from "../../stores";
import { useApi } from "../../api/api";
import { useCallback, useMemo, useState } from "react";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { usePoliciesData } from "./usePoliciesData";
import { useServerErrorHandler } from "@src/utils/useServerErrorHandler";
import { v4 as uuidv4 } from "uuid";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";
import type { Search200ResponsePoliciesInner } from "@src/gen/org-portal";

const { None, Some } = Helpers;
const SCREEN_NAME = "Policies";

export type TabKey = "owned" | "shared";

export const PoliciesScreen = () => {
  const { theme } = useTheme();
  const { api } = useApi();
  const { selectedOrgId } = useLoggedInAuthState();
  const { policiesLimit } = useAppConstantsStore();
  const navigation = useNavigation<NativeStackNavigationProp<RouteParams, "Policies">>();
  const { configuration } = useLoadedConfigurationState();
  const [activeTab, setActiveTab] = useState<TabKey>("owned");
  const { DefaultLayout } = useFlaggedLayout();
  const { deleteAlert, setAlert } = useAlertStore();
  const { errorHandler } = useServerErrorHandler();

  const [isFetching, setIsFetching] = useState(false);
  const [policiesSearchResults, setPoliciesSearchResults] =
    useState<readonly Search200ResponsePoliciesInner[]>();

  const {
    data: policiesData,
    fetchPolicies,
    isLoading,
    nextPage,
    pagination,
    prevPage,
    refresh,
    setParams,
  } = usePoliciesData(api, {
    limit: policiesLimit,
    orgID: selectedOrgId,
    ownerID: activeTab === "shared" ? configuration.solUUID : undefined,
  });

  const TAB_LIST: readonly { readonly key: TabKey; readonly label: string }[] = useMemo(() => {
    if (selectedOrgId === configuration.solUUID) {
      return [{ key: "owned", label: "Our policies" }];
    }

    return [
      { key: "owned", label: "Our policies" },
      { key: "shared" as const, label: "Policies shared with us" },
    ];
  }, [selectedOrgId, configuration.solUUID]);

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      flexDirection: "column",
    },
    content: {
      flex: 1,
    },
  });

  const [selectedPolicyID, setSelectedPolicyID] = useState<string>();

  useFocusEffect(
    useCallback(() => {
      fetchPolicies();
    }, [fetchPolicies])
  );

  useFocusEffect(
    useCallback(
      () => () => {
        setParams({
          end: undefined,
          limit: policiesLimit,
          orgID: selectedOrgId,
          ownerID: undefined,
          start: undefined,
        });
        setActiveTab("owned");
      },
      [policiesLimit, selectedOrgId, setParams]
    )
  );

  const fetchPoliciesBySearchQuery = async (searchQuery: string) => {
    try {
      setIsFetching(true);
      const { hasMore, policies } = await api.search({
        orgID: selectedOrgId,
        query: {
          objectName: searchQuery,
          objectType: "policy",
        },
      });
      if (Some(hasMore) && hasMore) {
        setAlert({
          closable: true,
          id: uuidv4(),
          onPressDelete: id => {
            deleteAlert(id);
          },
          text: `Your search returned more than 500 results. Please refine you search`,
          type: "warning",
        });
      }
      setPoliciesSearchResults(policies);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsFetching(false);
    }
  };

  const policies: readonly Search200ResponsePoliciesInner[] = policiesSearchResults ?? policiesData;

  return (
    <>
      <DefaultLayout
        breadcrumbs={[{ name: "Create & Configure" }, { name: "Policies" }]}
        headerRightElements={
          <>
            <SearchForm
              disabled={isFetching}
              onClear={() => {
                setPoliciesSearchResults(undefined);
              }}
              onSearch={value => {
                fetchPoliciesBySearchQuery(value);
              }}
            />
            <Button
              onPress={() => {
                navigation.navigate("CreatePolicy");
              }}
              size="small"
              testProps={{
                elementName: "createPolicyButton",
                screenName: SCREEN_NAME,
              }}
              text="Create policy"
            />
          </>
        }
        tabs={
          <Tabs
            items={TAB_LIST}
            onChange={value => {
              setSelectedPolicyID(undefined);
              navigation.navigate("Policies");
              setActiveTab(value);
              refresh({
                end: undefined,
                limit: policiesLimit,
                orgID: selectedOrgId,
                ownerID: value === "shared" ? configuration.solUUID : undefined,
                start: undefined,
              });
            }}
            selected={activeTab}
            testProps={{
              elementName: "policies",
              screenName: SCREEN_NAME,
            }}
          />
        }
        testProps={{
          elementName: "policies",
          screenName: SCREEN_NAME,
        }}
        title="Policies"
      >
        <View style={styles.container}>
          {isFetching ? (
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Spin />
            </View>
          ) : (
            <>
              <VerticalSpacer size={theme.spacing.xxl} />
              <View style={styles.content}>
                {Some(policiesSearchResults) ? (
                  <>
                    <View style={{ paddingLeft: 32 }}>
                      <Heading level="4">{`Results(${policiesSearchResults.length})`}</Heading>
                    </View>
                    <PoliciesTable
                      onPressView={policyID => {
                        setSelectedPolicyID(policyID);
                      }}
                      polices={policies}
                    />
                  </>
                ) : (
                  <ScrollView>
                    {activeTab === "owned" &&
                      (isLoading ? (
                        <View style={{ flex: 1, justifyContent: "center" }}>
                          <Spin />
                        </View>
                      ) : (
                        <PoliciesTable
                          onPressView={policyID => {
                            setSelectedPolicyID(policyID);
                          }}
                          polices={policies}
                        />
                      ))}
                    {activeTab === "shared" && (
                      <SharedPolicies
                        onPressView={policyID => {
                          setSelectedPolicyID(policyID);
                        }}
                      />
                    )}
                  </ScrollView>
                )}
              </View>
              {None(policiesSearchResults) && (
                <Pagination
                  disableNext={!pagination.hasNextPage}
                  disablePrev={!pagination.hasPrevPage}
                  onNext={nextPage}
                  onPrev={prevPage}
                  testProps={{
                    elementName:
                      activeTab === "owned" ? "ourPoliciesListView" : "sharedPoliciesListView",
                    screenName: SCREEN_NAME,
                  }}
                />
              )}
            </>
          )}
        </View>
      </DefaultLayout>
      <Drawer isOpen={Some(selectedPolicyID)}>
        <PolicyDetails
          onClose={() => {
            setSelectedPolicyID(undefined);
          }}
          onStatusChange={refresh}
          policyID={selectedPolicyID}
          policyType={activeTab}
        />
      </Drawer>
    </>
  );
};
