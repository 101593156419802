/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContainerFieldQuery } from './ContainerFieldQuery';
import {
    ContainerFieldQueryFromJSON,
    ContainerFieldQueryFromJSONTyped,
    ContainerFieldQueryToJSON,
} from './ContainerFieldQuery';

/**
 * Representation of a search query.
 * @export
 * @interface SearchQuery
 */
export interface SearchQuery {
    /**
     * The type of object to search for
     * @type {string}
     * @memberof SearchQuery
     */
    objectType?: SearchQueryObjectTypeEnum | null;
    /**
     * The prefix or name of the object (template, fieldkind, datasource or policy) to search for
     * @type {string}
     * @memberof SearchQuery
     */
    objectName?: string | null;
    /**
     * Container field values to query.
     * @type {Array<ContainerFieldQuery>}
     * @memberof SearchQuery
     */
    containerFields?: Array<ContainerFieldQuery>;
    /**
     * The recipient ID to search for
     * @type {string}
     * @memberof SearchQuery
     */
    recipientID?: string | null;
    /**
     * The template ID to search for
     * @type {string}
     * @memberof SearchQuery
     */
    templateID?: string | null;
    /**
     * The template type to search for
     * @type {string}
     * @memberof SearchQuery
     */
    templateType?: SearchQueryTemplateTypeEnum | null;
    /**
     * The state of the container
     * @type {string}
     * @memberof SearchQuery
     */
    state?: SearchQueryStateEnum | null;
    /**
     * RFC 3339, section 5.6 date-time (with optional offset)
     * @type {Date}
     * @memberof SearchQuery
     */
    authorizedAtStart?: Date | null;
    /**
     * RFC 3339, section 5.6 date-time (with optional offset)
     * @type {Date}
     * @memberof SearchQuery
     */
    authorizedAtEnd?: Date | null;
    /**
     * The maximum number of search results to return, max 500
     * @type {number}
     * @memberof SearchQuery
     */
    limit?: number | null;
}


/**
 * @export
 */
export const SearchQueryObjectTypeEnum = {
    Template: 'template',
    Fieldkind: 'fieldkind',
    Datasource: 'datasource',
    Policy: 'policy'
} as const;
export type SearchQueryObjectTypeEnum = typeof SearchQueryObjectTypeEnum[keyof typeof SearchQueryObjectTypeEnum];

/**
 * @export
 */
export const SearchQueryTemplateTypeEnum = {
    Merit: 'Merit',
    Folio: 'Folio',
    Persona: 'Persona'
} as const;
export type SearchQueryTemplateTypeEnum = typeof SearchQueryTemplateTypeEnum[keyof typeof SearchQueryTemplateTypeEnum];

/**
 * @export
 */
export const SearchQueryStateEnum = {
    Unissued: 'unissued',
    Pending: 'pending',
    Accepted: 'accepted',
    Rejected: 'rejected',
    Revoked: 'revoked'
} as const;
export type SearchQueryStateEnum = typeof SearchQueryStateEnum[keyof typeof SearchQueryStateEnum];


/**
 * Check if a given object implements the SearchQuery interface.
 */
export function instanceOfSearchQuery(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchQueryFromJSON(json: any): SearchQuery {
    return SearchQueryFromJSONTyped(json, false);
}

export function SearchQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectType': !exists(json, 'objectType') ? undefined : json['objectType'],
        'objectName': !exists(json, 'objectName') ? undefined : json['objectName'],
        'containerFields': !exists(json, 'containerFields') ? undefined : ((json['containerFields'] as Array<any>).map(ContainerFieldQueryFromJSON)),
        'recipientID': !exists(json, 'recipientID') ? undefined : json['recipientID'],
        'templateID': !exists(json, 'templateID') ? undefined : json['templateID'],
        'templateType': !exists(json, 'templateType') ? undefined : json['templateType'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'authorizedAtStart': !exists(json, 'authorized_at_start') ? undefined : (json['authorized_at_start'] === null ? null : new Date(json['authorized_at_start'])),
        'authorizedAtEnd': !exists(json, 'authorized_at_end') ? undefined : (json['authorized_at_end'] === null ? null : new Date(json['authorized_at_end'])),
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
    };
}

export function SearchQueryToJSON(value?: SearchQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'objectType': value.objectType,
        'objectName': value.objectName,
        'containerFields': value.containerFields === undefined ? undefined : ((value.containerFields as Array<any>).map(ContainerFieldQueryToJSON)),
        'recipientID': value.recipientID,
        'templateID': value.templateID,
        'templateType': value.templateType,
        'state': value.state,
        'authorized_at_start': value.authorizedAtStart === undefined ? undefined : (value.authorizedAtStart === null ? null : value.authorizedAtStart.toISOString()),
        'authorized_at_end': value.authorizedAtEnd === undefined ? undefined : (value.authorizedAtEnd === null ? null : value.authorizedAtEnd.toISOString()),
        'limit': value.limit,
    };
}

