// Copyright 2023 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import { useAlertStore } from "../stores";
import { useApi } from "../api/api";
import { useServerErrorHandler } from "../utils/useServerErrorHandler";
import { useState } from "react";

const { Some } = Helpers;

export const useLogout = () => {
  const { errorHandler } = useServerErrorHandler();
  const { api } = useApi();

  const [isLoading, setIsLoading] = useState(false);
  const { deleteAlert, setAlert } = useAlertStore();

  const logout = async () => {
    try {
      setIsLoading(true);
      const { link } = await api.getLogoutLink({ host: window.location.host });
      if (Some(link)) {
        // call logout success to invalid session
        // if successful, redirect to logout link
        // this must be done before using the logout link as auth0 will redirect back without any session/jwt info
        await api.logoutSuccess();
        window.location.replace(link);
      } else {
        setAlert({
          closable: true,
          id: "logout",
          onPressDelete: id => {
            deleteAlert(id);
          },
          text: "Something wrong, please try again",
          type: "error",
        });
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, logout };
};
