/* eslint-disable no-script-url */

import { type Accept, type FileRejection, useDropzone } from "react-dropzone";
import { Body, useTheme } from "@merit/frontend-components";
import { Helpers } from "@merit/frontend-utils";
import { Image, StyleSheet, View } from "react-native";
import { Images } from "@src/utils/Image";
import { useAlertStore } from "@src/stores";
import { v4 as uuidv4 } from "uuid";
import React, { useCallback } from "react";

type Props = {
  readonly acceptedFileTypes: Accept;
  readonly onDrop: (
    acceptedFiles: readonly File[],
    fileRejections: readonly FileRejection[]
  ) => void;
  readonly isTemplateSelected?: boolean;
};

const { Some } = Helpers;

export const Dropzone = ({ acceptedFileTypes, isTemplateSelected, onDrop }: Props) => {
  const { theme } = useTheme();
  const { deleteAlert, setAlert } = useAlertStore();

  const { getInputProps, getRootProps } = useDropzone({
    accept: acceptedFileTypes,
    maxFiles: 1,
    onDrop,
  });

  const styles = StyleSheet.create({
    container: {
      alignItems: "center",
      color: "#bdbdbd",
      margin: theme.spacing.xl,
      padding: theme.spacing.xl,
      textAlign: "center",
    },
  });

  const showErrorAlert = useCallback(() => {
    setAlert({
      closable: true,
      id: uuidv4(),
      onPressDelete: id => {
        deleteAlert(id);
      },
      size: "medium",
      text: "Please select template type",
      type: "error",
    });
  }, [deleteAlert, setAlert]);

  return (
    <div
      {...getRootProps({
        onClick: event => {
          if (Some(isTemplateSelected) && !isTemplateSelected) {
            event.preventDefault();
            event.stopPropagation();
            showErrorAlert();
          }
        },
      })}
    >
      <input {...getInputProps()} />
      <View style={styles.container}>
        <Image
          accessibilityLabel="select-document"
          source={Images.selectFile}
          style={{ height: 48, marginVertical: theme.spacing.xl, width: 48 }}
        />
        <Body center size="l">
          Drop your file here or{" "}
          <a href="javascript:void(0)" style={{ textDecoration: "none" }}>
            browse
          </a>
        </Body>
        <Body
          center
          size="l"
          style={{ color: theme.colors.text.subdued, paddingVertical: theme.spacing.m }}
        >
          Supported files: CSV
        </Body>
      </View>
    </div>
  );
};
