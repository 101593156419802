/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Container } from './Container';
import {
    ContainerFromJSON,
    ContainerFromJSONTyped,
    ContainerToJSON,
} from './Container';
import type { DataSourceSearchResult } from './DataSourceSearchResult';
import {
    DataSourceSearchResultFromJSON,
    DataSourceSearchResultFromJSONTyped,
    DataSourceSearchResultToJSON,
} from './DataSourceSearchResult';
import type { FieldKindSearchResult } from './FieldKindSearchResult';
import {
    FieldKindSearchResultFromJSON,
    FieldKindSearchResultFromJSONTyped,
    FieldKindSearchResultToJSON,
} from './FieldKindSearchResult';
import type { PolicySearchResult } from './PolicySearchResult';
import {
    PolicySearchResultFromJSON,
    PolicySearchResultFromJSONTyped,
    PolicySearchResultToJSON,
} from './PolicySearchResult';
import type { TemplateSearchResult } from './TemplateSearchResult';
import {
    TemplateSearchResultFromJSON,
    TemplateSearchResultFromJSONTyped,
    TemplateSearchResultToJSON,
} from './TemplateSearchResult';

/**
 * Results of a Search Query.
 * @export
 * @interface SearchResults
 */
export interface SearchResults {
    /**
     * The list of container search results.
     * @type {Array<Container>}
     * @memberof SearchResults
     */
    containers?: Array<Container>;
    /**
     * The list of template search results.
     * @type {Array<TemplateSearchResult>}
     * @memberof SearchResults
     */
    templates?: Array<TemplateSearchResult>;
    /**
     * The list of DataSource search results.
     * @type {Array<DataSourceSearchResult>}
     * @memberof SearchResults
     */
    dataSources?: Array<DataSourceSearchResult>;
    /**
     * The list of FieldKind search results.
     * @type {Array<FieldKindSearchResult>}
     * @memberof SearchResults
     */
    fieldKinds?: Array<FieldKindSearchResult>;
    /**
     * The list of Policy search results.
     * @type {Array<PolicySearchResult>}
     * @memberof SearchResults
     */
    policies?: Array<PolicySearchResult>;
    /**
     * Whether there are more search results
     * @type {boolean}
     * @memberof SearchResults
     */
    hasMore?: boolean;
}

/**
 * Check if a given object implements the SearchResults interface.
 */
export function instanceOfSearchResults(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchResultsFromJSON(json: any): SearchResults {
    return SearchResultsFromJSONTyped(json, false);
}

export function SearchResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'containers': !exists(json, 'containers') ? undefined : ((json['containers'] as Array<any>).map(ContainerFromJSON)),
        'templates': !exists(json, 'templates') ? undefined : ((json['templates'] as Array<any>).map(TemplateSearchResultFromJSON)),
        'dataSources': !exists(json, 'dataSources') ? undefined : ((json['dataSources'] as Array<any>).map(DataSourceSearchResultFromJSON)),
        'fieldKinds': !exists(json, 'fieldKinds') ? undefined : ((json['fieldKinds'] as Array<any>).map(FieldKindSearchResultFromJSON)),
        'policies': !exists(json, 'policies') ? undefined : ((json['policies'] as Array<any>).map(PolicySearchResultFromJSON)),
        'hasMore': !exists(json, 'hasMore') ? undefined : json['hasMore'],
    };
}

export function SearchResultsToJSON(value?: SearchResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'containers': value.containers === undefined ? undefined : ((value.containers as Array<any>).map(ContainerToJSON)),
        'templates': value.templates === undefined ? undefined : ((value.templates as Array<any>).map(TemplateSearchResultToJSON)),
        'dataSources': value.dataSources === undefined ? undefined : ((value.dataSources as Array<any>).map(DataSourceSearchResultToJSON)),
        'fieldKinds': value.fieldKinds === undefined ? undefined : ((value.fieldKinds as Array<any>).map(FieldKindSearchResultToJSON)),
        'policies': value.policies === undefined ? undefined : ((value.policies as Array<any>).map(PolicySearchResultToJSON)),
        'hasMore': value.hasMore,
    };
}

