// Copyright 2023 Merit International Inc. All Rights Reserved

import type {
  CreateDatasourceRequestIntegrationTypeEnum,
  CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum,
  CreateDatasourceRequestSchemaDelimiterEnum,
} from "../../gen/org-portal";

type Option<Value extends string> = { readonly label: string; readonly value: Value };
type IntegrationType = CreateDatasourceRequestIntegrationTypeEnum;
type Delimiter = CreateDatasourceRequestSchemaDelimiterEnum;
type DataType = CreateDatasourceRequestSchemaColumnsInnerColumnDataTypeEnum;

const INTEGRATION_TYPE_OPTIONS: readonly Option<IntegrationType>[] = [
  { label: "Preset adapter", value: "CSV" },
] as const;

const DELIMITER_OPTIONS: readonly Option<Delimiter>[] = [
  { label: "Comma", value: "comma" },
  { label: "Tab", value: "tab" },
  { label: "Vertical bar / Pipe", value: "pipe" },
] as const;

const DATA_TYPE_OPTIONS: readonly Option<DataType>[] = [
  { label: "Blob", value: "Blob" },
  { label: "Boolean", value: "Bool" },
  { label: "Date", value: "Date" },
  { label: "DateTime", value: "DateTime" },
  { label: "Email", value: "Email" },
  { label: "JSON", value: "JSON" },
  { label: "Markdown", value: "Markdown" },
  { label: "Number", value: "Number" },
  { label: "Phone Number", value: "PhoneNumber" },
  { label: "Text", value: "Text" },
] as const;

const BOOL_OPTIONS: readonly Option<DataType>[] = DATA_TYPE_OPTIONS.filter(t => t.value === "Bool");

function GetDatasourceColumnTypes(
  showJsonFieldTypes: boolean,
  usedAsDeletion: boolean
): readonly Option<DataType>[] {
  if (usedAsDeletion) {
    return BOOL_OPTIONS;
  }
  if (!showJsonFieldTypes) {
    return DATA_TYPE_OPTIONS.filter(dt => dt.label !== "JSON");
  }

  return DATA_TYPE_OPTIONS;
}

export { INTEGRATION_TYPE_OPTIONS, DELIMITER_OPTIONS, GetDatasourceColumnTypes };
