import { Body, Button, useTheme } from "@merit/frontend-components";
import {
  Datagrid,
  DatagridBody,
  EllipsisText,
  Pagination,
  Spin,
  VerticalSpacer,
} from "@src/components";
import { Helpers } from "@merit/frontend-utils";
import { SCREEN_NAME } from "@src/screens/Templates/Templates";
import { ScrollView, View } from "react-native";
import { TemplatesFilter } from "@src/screens/Templates/TemplatesFilter";
import { useApi } from "@src/api/api";
import { useEffect } from "react";
import { useLoadedConfigurationState } from "@src/hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "@src/hooks/loggedInAuthState";
import { useTemplateDataList } from "@src/screens/Templates/useTemplateDataList";
import lowerCase from "lodash.lowercase";
import type { DatagridColumn } from "@src/components/Datagrid/types";
import type { ListTemplates200ResponseTemplatesInner } from "@src/gen/org-portal";
import type { ReactNode } from "react";

type Props = {
  readonly setTemplateID: (id: string) => void;
  readonly routeParams?: string | undefined;
  readonly refresh: boolean;
  readonly setRefresh: (value: boolean) => void;
};

const { Some } = Helpers;

export const TemplatesDatagridList = ({
  refresh,
  routeParams,
  setRefresh,
  setTemplateID,
}: Props) => {
  const { theme } = useTheme();
  const auth = useLoggedInAuthState();
  const { api } = useApi();
  const { configuration } = useLoadedConfigurationState();

  const templateList = useTemplateDataList(
    api,
    auth.selectedOrgId,
    routeParams === "shared" ? configuration.solUUID : undefined
  );

  useEffect(() => {
    if (refresh) {
      templateList.refresh();
      setRefresh(false);
    }
  }, [refresh, setRefresh, templateList]);

  const columns: readonly DatagridColumn<ListTemplates200ResponseTemplatesInner>[] = [
    { key: "name", label: "Name", size: "flex" },
    { key: "type", label: "Type", size: 128 },
    { key: "id", label: "Template ID", size: 360 },
    {
      key: "description",
      label: "Description",
      renderer: ({ description }) => (
        <EllipsisText text={Some(description) && description.length > 0 ? description : "--"} />
      ),
      size: "flex",
    },
    {
      key: "status",
      label: "Status",
      renderer: item => (
        <View style={{ alignItems: "center", flexDirection: "row" }}>
          <View
            style={{
              backgroundColor:
                lowerCase(item.status) === "live"
                  ? theme.colors.icon.alert.success
                  : theme.colors.icon.disabled,
              borderRadius: theme.borderRadii.m,
              height: 8,
              marginRight: theme.spacing.m,
              width: 8,
            }}
          />
          <Body capitalize>{item.status as ReactNode}</Body>
        </View>
      ),
      size: 128,
    },
    { key: "approval", label: "Approval", size: 128 },
    {
      key: "actions",
      label: "Actions",
      renderer: item => (
        <Button
          onPress={() => {
            setTemplateID(item.id);
          }}
          size="small"
          testProps={{
            elementId: item.id,
            elementName: "templatesListViewRowItemViewButton",
            screenName: SCREEN_NAME,
          }}
          text="View"
          type="secondary"
        />
      ),

      size: 128,
    },
  ];

  return (
    <>
      <TemplatesFilter
        onSelect={values => {
          templateList.filterData(values);
        }}
        statusPlaceholder="All"
        typePlaceholder="All"
      />
      <VerticalSpacer size={theme.spacing.xxl} />
      {templateList.loading ? (
        <View style={{ flex: 1 }}>
          <Spin />
        </View>
      ) : (
        <ScrollView>
          <Datagrid
            columns={columns}
            loading={templateList.loading}
            testProps={{ elementName: "ownTemplatesListView", screenName: SCREEN_NAME }}
          >
            <DatagridBody
              columns={columns}
              data={templateList.data}
              testProps={{
                elementName: "ownTemplatesListView",
                screenName: SCREEN_NAME,
              }}
              testPropsElementIdKey="id"
            />
          </Datagrid>
        </ScrollView>
      )}

      <Pagination
        disableNext={!templateList.pagination.hasNextPage}
        disablePrev={!templateList.pagination.hasPrevPage}
        onNext={() => {
          templateList.nextPage();
        }}
        onPrev={() => {
          templateList.prevPage();
        }}
        testProps={{ elementName: "templatesListView", screenName: SCREEN_NAME }}
      />
    </>
  );
};
