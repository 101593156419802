// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Heading, useTheme } from "@merit/frontend-components";
import { DetailsDrawer } from "../../layouts/DetailsDrawer";
import { Drawer } from "../../components/Drawer";
import { Fields } from "./Fields";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../../components/Spacer";
import { Tabs } from "../../components";
import { View } from "react-native";
import { containerStatesToShowRejectButton } from "../OrgSettings/ConnectedOrganizations/ConnectedOrganizationsTable";
import { getContainerFieldValue } from "../../utils/getContainerFieldValue";
import { useAppConstantsStore } from "../../stores";
import { useFolioDetailsScreenStyles } from "./styles";
import { useState } from "react";
import type { OrgsGet200ResponseContainersInner as Container } from "../../gen/org-portal";

type TabName = "fields" | "overview";
const tabs: readonly { readonly key: TabName; readonly label: string }[] = [
  { key: "overview", label: "Overview" },
  { key: "fields", label: "Fields" },
];

type Props = {
  readonly isOpen: boolean;
  readonly onDrawerClose: () => void;
  readonly container: Container | undefined;
  readonly acceptFolio: (folioId: string, folioTemplateId: string | undefined) => Promise<void>;
  readonly onReject: (folioId: string | undefined) => void;
};

const { Some } = Helpers;
const SCREEN_NAME = "ConnectedOrganizationDetails";

export const ConnectedOrganizationsDetails = ({
  acceptFolio,
  container,
  isOpen,
  onDrawerClose,
  onReject,
}: Props) => {
  const { theme } = useTheme();
  const { folioFieldNames } = useAppConstantsStore();
  const styles = useFolioDetailsScreenStyles();

  const [selectedTab, setSelectedTab] = useState<TabName>("overview");

  return (
    <Drawer isOpen={isOpen}>
      <DetailsDrawer
        actionButtons={
          <View style={styles.row}>
            {Some(container?.state?.name) &&
              containerStatesToShowRejectButton.includes(container.state.name) && (
                <View style={{ width: 97 }}>
                  <Button
                    onPress={() => {
                      onReject(container.id);
                    }}
                    size="small"
                    testProps={{
                      elementId: container.id,
                      elementName: "connectedOrganizationDetailsViewRejectButton",
                      screenName: SCREEN_NAME,
                    }}
                    text="Reject"
                    type="destructive"
                  />
                </View>
              )}
            <HorizontalSpacer />
            {container?.state?.name === "pending" && (
              <View style={{ width: 120 }}>
                <Button
                  iconLeft="checkmarkSuccess"
                  onPress={() => acceptFolio(container.id, container.templateId)}
                  size="small"
                  testProps={{
                    elementId: container.id,
                    elementName: "connectedOrganizationDetailsViewAcceptButton",
                    screenName: SCREEN_NAME,
                  }}
                  text="Accept"
                  type="primary"
                />
              </View>
            )}
          </View>
        }
        onPressClose={() => {
          onDrawerClose();
        }}
        subTitle={
          <View style={styles.row}>
            <Heading
              level="6"
              testProps={{
                elementId: container?.id,
                elementName: "connectedOrganizationDetailsViewFolioID",
                screenName: SCREEN_NAME,
              }}
            >
              Folio ID: {container?.id}
            </Heading>
            <HorizontalSpacer size={10} />
            <Heading color={theme.colors.border.subdued} level="6">
              |
            </Heading>
            <HorizontalSpacer size={10} />
            <Heading
              capitalize
              level="6"
              testProps={{
                elementId: container?.id,
                elementName: "connectedOrganizationDetailsViewFolioStatus",
                screenName: SCREEN_NAME,
              }}
            >
              Status: {Some(container) && container.active === true ? "Active" : "Inactive"}
            </Heading>
            <HorizontalSpacer size={10} />
            <Heading color={theme.colors.border.subdued} level="6">
              |
            </Heading>
            <HorizontalSpacer size={10} />
            <Heading
              capitalize
              level="6"
              testProps={{
                elementId: container?.id,
                elementName: "connectedOrganizationDetailsViewFolioState",
                screenName: SCREEN_NAME,
              }}
            >
              State:
              {Some(container) && Some(container.state) ? ` ${container.state.name}` : "--"}
            </Heading>
          </View>
        }
        testProps={{
          elementId: container?.id,
          elementName: "connectedOrganizationDetailsView",
          screenName: SCREEN_NAME,
        }}
        title={container?.name === undefined ? "" : container.name}
      >
        <View style={styles.tabsContainer}>
          <Tabs
            items={tabs}
            onChange={tab => {
              setSelectedTab(tab);
            }}
            selected={selectedTab}
            testProps={{
              elementId: container?.id,
              elementName: "connectedOrganizationDetailsView",
              screenName: SCREEN_NAME,
            }}
          />
        </View>
        <View style={styles.tabBody}>
          {selectedTab === "overview" && (
            <View style={{ paddingHorizontal: 32 }}>
              <>
                <Heading level="5">Description</Heading>
                <VerticalSpacer size={theme.spacing.xs} />
                <Body
                  size="s"
                  testProps={{
                    elementId: container?.id,
                    elementName: "connectedOrganizationDetailsViewOverviewTabDescription",
                    screenName: SCREEN_NAME,
                  }}
                >
                  {container?.description}
                </Body>
              </>
              <VerticalSpacer size={theme.spacing.xxl} />
              <>
                <Heading level="5">Type</Heading>
                <VerticalSpacer size={theme.spacing.xs} />
                <Body
                  size="s"
                  testProps={{
                    elementId: container?.id,
                    elementName: "connectedOrganizationDetailsViewOverviewTabTemplateType",
                    screenName: SCREEN_NAME,
                  }}
                >
                  Folio
                </Body>
              </>
              <VerticalSpacer size={theme.spacing.xxl} />
              <>
                <Heading level="5">Sent by</Heading>
                <VerticalSpacer size={theme.spacing.xs} />
                <Body
                  size="s"
                  testProps={{
                    elementId: container?.id,
                    elementName: "connectedOrganizationDetailsViewOverviewTabSentBy",
                    screenName: SCREEN_NAME,
                  }}
                >
                  {Some(container)
                    ? getContainerFieldValue(folioFieldNames.issuingOrgName, container)
                    : "--"}
                </Body>
              </>
              <VerticalSpacer size={theme.spacing.xxl} />
              <>
                <Heading level="5">Recipient</Heading>
                <VerticalSpacer size={theme.spacing.xs} />
                <View style={{ flexDirection: "row" }}>
                  <Body
                    size="s"
                    testProps={{
                      elementId: container?.id,
                      elementName: "connectedOrganizationDetailsViewOverviewTabRecipient",
                      screenName: SCREEN_NAME,
                    }}
                  >
                    {Some(container)
                      ? getContainerFieldValue(folioFieldNames.organizationName, container)
                      : "--"}
                  </Body>
                </View>
              </>
            </View>
          )}
          {selectedTab === "fields" && <Fields fields={container?.fields ?? []} />}
        </View>
      </DetailsDrawer>
    </Drawer>
  );
};
