// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Heading, useTheme } from "@merit/frontend-components";
import { HorizontalSpacer, VerticalSpacer } from "../components/Spacer";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { Images } from "../utils/Image";
import { PreLoginLayout } from "../layouts/PreLoginLayout";
import { Spin } from "../components";
import { useLogout } from "../utils/useLogout";
import React from "react";

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    flex: 1,
  },
});

export const NoPermissions = () => {
  const { theme } = useTheme();
  const { isLoading, logout } = useLogout();

  return (
    <PreLoginLayout showLogoutButton>
      <View style={styles.container}>
        <View style={{ width: 420 }}>
          <VerticalSpacer size={40} />
          <View style={{ alignItems: "center" }}>
            <Image
              accessibilityLabel="lock"
              source={Images.lock}
              style={{ height: 120, width: 120 }}
            />
          </View>
          <VerticalSpacer size={40} />
          <Heading level="1">You are not granted permission to access this site</Heading>
          <VerticalSpacer />
          <Body>
            Your current permissions do not grant you access to the Merit Org Portal. Please contact
            your organization if you need access.
          </Body>
          <VerticalSpacer size={40} />
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <Pressable
              onPress={() => {
                window.open("https://knowledge.merits.com/", "_blank");
              }}
            >
              <Body color={theme.colors.text.link}>Help Center</Body>
            </Pressable>
            <HorizontalSpacer size={theme.spacing.xxl} />
            {isLoading ? (
              <View style={{ width: 160 }}>
                <Spin />
              </View>
            ) : (
              <Button onPress={logout} size="large" text="Login to Merit" />
            )}
          </View>
        </View>
      </View>
    </PreLoginLayout>
  );
};
