// Copyright 2024 Merit International Inc. All Rights Reserved

import { Button, useTheme } from "@merit/frontend-components";
import { Drawer } from "../../components/Drawer";
import { Helpers } from "@merit/frontend-utils";
import { SearchForm } from "@src/components/SearchForm/SearchForm";
import { SharedTemplates } from "./SharedTemplates";
import { StyleSheet, View } from "react-native";
import { Tabs } from "../../components";
import { TemplateDetails } from "../TemplateDetails";
import { TemplatesDatagridList } from "@src/screens/Templates/TemplatesDatagridList";
import { TemplatesDatagridSearch } from "@src/screens/Templates/TemplatesDatagridSearch";
import { useAlertStore } from "@src/stores";
import { useEffect, useMemo, useState } from "react";
import { useFlaggedLayout } from "@src/hooks/useFlaggedLayout";
import { useIsFocused, useNavigation, useRoute } from "@react-navigation/native";
import { useLoadedConfigurationState } from "../../hooks/useLoadedConfigurationState";
import { useLoggedInAuthState } from "../../hooks/loggedInAuthState";
import { v4 as uuidv4 } from "uuid";
import type { NativeStackNavigationProp } from "@react-navigation/native-stack";
import type { RouteParams } from "../../Router";
import type { RouteProp } from "@react-navigation/native";

export const SCREEN_NAME = "Templates";

export type TabKey = "owned" | "shared";

type Navigation = NativeStackNavigationProp<RouteParams, "Templates">;

const { None, Some } = Helpers;

export const TemplatesScreen = () => {
  // States
  const { params: routeParams } = useRoute<RouteProp<RouteParams, "Templates">>();
  const [activeTab, setActiveTab] = useState<TabKey>("owned");
  const [templateID, setTemplateID] = useState<string>();
  const [refreshDatagrid, setRefreshDatagrid] = useState<boolean>(false);
  const [resultsCount, setResultsCount] = useState<number | undefined>(undefined);
  const { deleteAlert, setAlert } = useAlertStore();
  const [searchQuery, setSearchQuery] = useState<string>();

  // Hooks
  const navigation = useNavigation<Navigation>();
  const auth = useLoggedInAuthState();
  const { theme } = useTheme();
  const { configuration } = useLoadedConfigurationState();
  const { DefaultLayout } = useFlaggedLayout();
  const isFocused = useIsFocused();

  const tab = None(routeParams) ? undefined : routeParams.tab;

  useEffect(() => {
    const initialTemplateId = routeParams === undefined ? undefined : routeParams.initialTemplateId;
    setTemplateID(initialTemplateId);
  }, [routeParams]);

  const TAB_LIST = useMemo(
    () =>
      auth.selectedOrgId === configuration.solUUID
        ? [{ key: "owned", label: "Our templates" }]
        : [
            { key: "owned", label: "Our templates" },
            { key: "shared" as const, label: "Templates shared with us" },
          ],
    [auth.selectedOrgId, configuration.solUUID]
  );

  useEffect(() => {
    if (auth.selectedOrgId !== configuration.solUUID && tab === TAB_LIST[1].key) {
      setActiveTab("shared");
    } else {
      setActiveTab("owned");
    }
  }, [TAB_LIST, auth.selectedOrgId, configuration.solUUID, routeParams, tab]);

  useEffect(() => {
    if (resultsCount !== undefined && resultsCount >= 500) {
      setAlert({
        closable: true,
        id: uuidv4(),
        onPressDelete: id => {
          deleteAlert(id);
        },
        size: "medium",
        testProps: {
          elementName: "tooManyRecordsInSearch",
          screenName: SCREEN_NAME,
        },
        text: `More than 500 records found, please refine your search.`,
        type: "warning",
      });
    }
  }, [deleteAlert, resultsCount, setAlert]);

  const styles = StyleSheet.create({
    content: {
      flex: 1,
    },
    wrapper: {
      backgroundColor: theme.colors.background.white,
      flex: 1,
      flexDirection: "column",
    },
  });

  useEffect(() => {
    if (isFocused) {
      setRefreshDatagrid(true);
    } else {
      setSearchQuery(undefined);
    }
  }, [isFocused]);

  return (
    <>
      <DefaultLayout
        breadcrumbs={[{ name: "Create & Configure" }, { name: "Templates" }]}
        headerRightElements={
          <>
            <SearchForm
              initialValue={searchQuery}
              onClear={() => {
                setSearchQuery(undefined);
              }}
              onSearch={value => {
                setSearchQuery(value);
              }}
            />
            <Button
              onPress={() => {
                navigation.navigate("CreateTemplate");
              }}
              size="small"
              testProps={{ elementName: "createTemplateButton", screenName: SCREEN_NAME }}
              text="Create template"
            />
          </>
        }
        tabs={
          <Tabs
            items={TAB_LIST}
            onChange={value => {
              navigation.navigate("Templates", { tab: value as TabKey });
            }}
            selected={activeTab}
            testProps={{ elementName: "templatesListView", screenName: SCREEN_NAME }}
          />
        }
        testProps={{ elementName: "templates", screenName: SCREEN_NAME }}
        title="Templates"
      >
        <View style={styles.wrapper}>
          <View style={styles.content}>
            {activeTab === "owned" ? (
              <>
                {None(searchQuery) ? (
                  <>
                    <TemplatesDatagridList
                      refresh={refreshDatagrid}
                      routeParams={activeTab}
                      setRefresh={setRefreshDatagrid}
                      setTemplateID={setTemplateID}
                    />
                  </>
                ) : (
                  <TemplatesDatagridSearch
                    refresh={refreshDatagrid}
                    routeParams={activeTab}
                    searchQuery={searchQuery}
                    setRefresh={setRefreshDatagrid}
                    setResultsCount={setResultsCount}
                    setTemplateID={setTemplateID}
                  />
                )}
              </>
            ) : (
              <>
                {None(searchQuery) ? (
                  <SharedTemplates
                    onPressViewTemplate={id => {
                      setTemplateID(id);
                    }}
                  />
                ) : (
                  <TemplatesDatagridSearch
                    refresh={refreshDatagrid}
                    routeParams={activeTab}
                    searchQuery={searchQuery}
                    setRefresh={setRefreshDatagrid}
                    setResultsCount={setResultsCount}
                    setTemplateID={setTemplateID}
                  />
                )}
              </>
            )}
          </View>
        </View>
      </DefaultLayout>

      <Drawer isOpen={Some(templateID)}>
        <TemplateDetails
          onClose={() => {
            setTemplateID(undefined);
          }}
          onStatusChange={() => {
            setRefreshDatagrid(true);
          }}
          templateID={templateID}
        />
      </Drawer>
    </>
  );
};
