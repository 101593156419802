// Copyright 2022 Merit International Inc. All Rights Reserved.

import { AssertIsDefined, AssertIsString } from "../utils/asserts";
import { useAuthStore } from "../stores/authStore";
import type { JwtPayload } from "jwt-decode";

export type JwtClaims = JwtPayload & {
  readonly name: string;
  readonly nickname: string;
};

export type AuthState = {
  readonly userCanRegisterOrgs: boolean;
  readonly selectedOrgId: string;
  readonly selectedOrgName: string;
  readonly accessToken: string;
  readonly session: string;
  readonly profile: JwtClaims;
};

export const useLoggedInAuthState = (): AuthState => {
  const { accessToken, profile, selectedOrgId, selectedOrgName, session } = useAuthStore();

  AssertIsString(accessToken, "Access token must be a string");
  AssertIsDefined(profile, "Profile must not be null");

  AssertIsString(selectedOrgId, "Selected org id must not be null");
  AssertIsString(selectedOrgName, "Selected org name must not be null");
  AssertIsString(session, "Session must not be null");

  const userCanRegisterOrgs = profile.name.includes("@gomerits.com");

  return {
    accessToken,
    profile,
    selectedOrgId,
    selectedOrgName,
    session,
    userCanRegisterOrgs,
  };
};
