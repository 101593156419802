// Copyright 2024 Merit International Inc. All Rights Reserved

import { Helpers } from "@merit/frontend-utils";
import { useAppConstantsStore } from "../../stores";
import { useEffect, useState } from "react";
import { useServerErrorHandler } from "../../utils/useServerErrorHandler";
import type {
  DefaultApi,
  ListTemplatesRequest,
  ListTemplatesStatusEnum,
  ListTemplatesTypeEnum,
  Search200Response,
} from "../../gen/org-portal";
import type { Filters } from "./TemplatesFilter";

const { Some } = Helpers;

type TemplatesFilters = Filters & {
  readonly searchQuery: string | undefined;
};

export const useTemplateDataSearch = (
  api: DefaultApi,
  orgID: string,
  ownerID?: string | undefined,
  searchQuery?: string | undefined,
  status?: ListTemplatesStatusEnum,
  type?: ListTemplatesTypeEnum
) => {
  const appConstants = useAppConstantsStore();
  // Constants
  const DEFAULT_PARAMS: ListTemplatesRequest & { readonly searchQuery?: string } = {
    limit: appConstants.templatesLimit,
    orgID,
    ownerID,
    searchQuery,
    status,
    type,
  } as const;

  // States
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<Search200Response>();
  const { errorHandler } = useServerErrorHandler();
  const [params, setParams] = useState<ListTemplatesRequest & { readonly searchQuery?: string }>(
    DEFAULT_PARAMS
  );

  // Computed Data
  const data = response?.templates ?? [];

  // Actions
  const refresh = (nextParams: Partial<ListTemplatesRequest> = {}) => {
    if (loading) {
      return;
    }

    setParams({
      ...params,
      ...nextParams,
    });
  };

  const filterData = (filterValues: TemplatesFilters | undefined) => {
    if (Some(orgID)) {
      const payload = {
        limit: 10,
        orgID,
        ...(Some(filterValues) && {
          searchQuery: filterValues.searchQuery ?? params.searchQuery,
          status: filterValues.status ?? params.status,
          type: filterValues.type ?? params.type,
        }),
      };
      setParams(payload);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (params.orgID === "") {
          throw new Error("Organization ID is undefined");
        }

        setLoading(true);

        await api
          .search({
            orgID: params.orgID,
            query: {
              objectName: params.searchQuery,
              templateType: params.type,
            },
          })
          .then(res => {
            if (params.type === undefined) {
              setResponse(res);
            } else {
              const filteredResults = Some(params.type)
                ? res.templates?.filter(template => template.type === params.type)
                : res.templates;
              setResponse({ ...res, templates: filteredResults });
            }
          });
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [api, errorHandler, params]);

  return {
    data,
    filterData,
    loading,
    refresh,
  };
};
