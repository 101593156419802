import { Body, Button, useTheme } from "@merit/frontend-components";
import { Dropzone } from "@src/components/Dropzone/Dropzone";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer, VerticalSpacer } from "../components/Spacer";
import { StyleSheet, View } from "react-native";
import React, { useCallback, useState } from "react";
import type { Accept, FileRejection } from "react-dropzone";

const { Some } = Helpers;

type Props = {
  readonly onSave: (file: File) => void;
  readonly onCancel: () => void;
  readonly acceptedFileTypes: Accept;
  readonly recommendationText?: string;
};

export const UploadFileModalDropzone = ({
  acceptedFileTypes,
  onCancel,
  onSave,
  recommendationText,
}: Props) => {
  const { theme } = useTheme();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [file, setFile] = useState<File>();

  const pickDocument = useCallback(
    (acceptedFiles: readonly File[], fileRejections: readonly FileRejection[]) => {
      acceptedFiles.forEach((blob: File) => {
        setFile(blob);
        setIsDisabled(false);
      });

      fileRejections.forEach(() => {
        setIsDisabled(true);
      });
    },
    []
  );

  const styles = StyleSheet.create({
    buttonsContainer: {
      alignItems: "center",
      alignSelf: "flex-end",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    container: {
      alignItems: "center",
      borderColor: theme.colors.border.subdued,
      borderWidth: 1,
      justifyContent: "center",
      minHeight: 200,
      minWidth: 456,
    },
  });

  return (
    <>
      <View style={styles.container}>
        <View style={{ paddingVertical: 38 }}>
          <VerticalSpacer size={theme.spacing.s} />
          <View style={{ alignItems: "center" }}>
            <Dropzone acceptedFileTypes={acceptedFileTypes} onDrop={pickDocument} />
          </View>
          <VerticalSpacer size={theme.spacing.s} />
          <Body style={{ color: theme.colors.text.subdued }}>{recommendationText}</Body>
        </View>
      </View>

      <VerticalSpacer />

      <View style={styles.buttonsContainer}>
        <View
          style={{
            flexDirection: "row",
          }}
        >
          <Button
            onPress={() => {
              onCancel();
            }}
            text="Cancel"
            type="secondary"
          />
          <HorizontalSpacer />
          <Button
            disabled={isDisabled}
            onPress={() => {
              if (Some(file)) {
                setIsDisabled(true);
                onSave(file);
              }
            }}
            text="Save"
            type="primary"
          />
        </View>
      </View>
    </>
  );
};
