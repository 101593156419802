// Copyright 2023 Merit International Inc. All Rights Reserved

import { HorizontalSpacer } from "../../components/Spacer";
import { SCREEN_NAME } from "./Templates";
import { Select, useTheme } from "@merit/frontend-components";
import { StyleSheet, View } from "react-native";
import React, { useState } from "react";
import type { ListTemplatesStatusEnum, ListTemplatesTypeEnum } from "../../gen/org-portal";

export type Filters = {
  readonly type?: ListTemplatesTypeEnum;
  readonly status?: ListTemplatesStatusEnum;
};

const TEMPLATE_TYPE_OPTIONS = [
  { label: "Merit", value: "Merit" },
  { label: "Folio", value: "Folio" },
];

const TEMPLATE_STATUS_OPTIONS = [
  { label: "Live", value: "live" },
  { label: "Paused", value: "paused" },
];

type Props = {
  readonly onSelect: (values?: Filters) => void;
  readonly defaultFilters?: Filters;
  readonly showStatus?: boolean;
  readonly statusPlaceholder?: string;
  readonly typePlaceholder?: string;
};

export const TemplatesFilter = ({
  defaultFilters,
  onSelect,
  showStatus = true,
  statusPlaceholder,
  typePlaceholder,
}: Props) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.surface.subdued,
      flexDirection: "row",
      paddingHorizontal: 32,
      paddingVertical: 16,
    },
    fieldItem: {
      minWidth: 325,
    },
  });

  const [filterValues, setFilterValues] = useState<Filters | undefined>(defaultFilters);

  return (
    <View style={styles.container}>
      {showStatus && (
        <>
          <View style={styles.fieldItem}>
            <Select
              defaultValue={TEMPLATE_STATUS_OPTIONS.find(_ => _.value === filterValues?.status)}
              label="Status"
              onSelectOption={option => {
                if (typeof option.value === "number") {
                  return;
                }
                const status = option.value as ListTemplatesStatusEnum;
                setFilterValues(prevState => ({ ...prevState, status }));
                onSelect({ ...filterValues, status });
              }}
              options={TEMPLATE_STATUS_OPTIONS}
              placeholder={{
                label: statusPlaceholder ?? "Status",
                value: "",
              }}
              testProps={{
                elementName: "filterModalViewTemplateStatus",
                screenName: SCREEN_NAME,
              }}
              usePortal
            />
          </View>
          <HorizontalSpacer />
        </>
      )}
      <View style={styles.fieldItem}>
        <Select
          defaultValue={TEMPLATE_TYPE_OPTIONS.find(_ => _.value === filterValues?.type)}
          label="Type"
          onSelectOption={option => {
            if (typeof option.value === "number") {
              return;
            }
            const type = option.value as ListTemplatesTypeEnum;
            setFilterValues(prevState => ({ ...prevState, type }));
            onSelect({ ...filterValues, type });
          }}
          options={TEMPLATE_TYPE_OPTIONS}
          placeholder={{
            label: typePlaceholder ?? "Type (Merit/Folio)",
            value: "",
          }}
          testProps={{
            elementName: "filterModalViewTemplateType",
            screenName: SCREEN_NAME,
          }}
          usePortal
        />
      </View>
    </View>
  );
};
