// Copyright 2024 Merit International Inc. All Rights Reserved.

import { useApi } from "@src/api/api";
import { useAppConstantsStore } from "@src/stores";
import { useEffect } from "react";
import { useLoadedConfigurationState } from "./useLoadedConfigurationState";
import { useLoggedInAuthState } from "./loggedInAuthState";
import { useOrgLogoStore } from "@src/stores/orgLogoStore";
import { useTheme } from "@merit/frontend-components";

export const useOrgLogo = (): string => {
  const { api } = useApi();
  const { theme } = useTheme();
  const { accountFolioFieldNames } = useAppConstantsStore();
  const { selectedOrgId } = useLoggedInAuthState();
  const { configuration } = useLoadedConfigurationState();
  const { getOrgLogo, setOrgLogo } = useOrgLogoStore();

  useEffect(() => {
    const fetchOrgLogo = async () => {
      const orgDetailsResponse = await api.getOrgDetails({
        orgID: selectedOrgId,
      });

      const logoField = orgDetailsResponse.org.fields?.find(
        field => field.name === accountFolioFieldNames.logo
      );

      if (logoField?.value !== undefined && logoField.value !== "") {
        const logoRes = await fetch(logoField.value);
        const logoText = await logoRes.text();
        setOrgLogo(selectedOrgId, logoText);
      }
    };

    if (selectedOrgId === configuration.solUUID) {
      return;
    }

    const existingLogo = getOrgLogo(selectedOrgId);
    if (existingLogo === undefined) {
      fetchOrgLogo();
    }
  }, [
    accountFolioFieldNames.logo,
    api,
    configuration.solUUID,
    getOrgLogo,
    selectedOrgId,
    setOrgLogo,
  ]);

  const orgLogo = getOrgLogo(selectedOrgId);

  return orgLogo === undefined ? (theme.images.orgLogo as string) : orgLogo;
};
