// Copyright 2023 Merit International Inc. All Rights Reserved

import { Body, Button, Icon, useTheme } from "@merit/frontend-components";
import { DetailsDrawer } from "../../layouts/DetailsDrawer";
import { Failures } from "./Failures";
import { Fields } from "./Fields";
import { Helpers } from "@merit/frontend-utils";
import { HorizontalSpacer } from "../../components/Spacer";
import { Overview } from "./Overview";
import { ScrollView, StyleSheet, View } from "react-native";
import { Tabs } from "../../components";
import React, { useState } from "react";
import type { ContainerWithTemplateType } from "./Approvals";
import type { ReactNode } from "react";

const { Some } = Helpers;
const SCREEN_NAME = "ApprovalDetails";

type TabKey = "failures" | "fields" | "overview";

type Props = {
  readonly containerData: ContainerWithTemplateType;
  readonly onClose: () => void;
  readonly onRemoveContainer: (containerID: string) => void;
  readonly onApproveContainer: (containerID: string) => Promise<void>;
};

export const ApprovalDetails = ({
  containerData,
  onApproveContainer,
  onClose,
  onRemoveContainer,
}: Props) => {
  const { theme } = useTheme();
  const [activeTab, setActiveTab] = useState<TabKey>("overview");

  const styles = StyleSheet.create({
    actionContainer: {
      flexDirection: "row",
    },
    tabsContainer: {
      borderBottomWidth: 1,
      borderColor: theme.colors.border.default,
      paddingHorizontal: 32,
    },
  });

  const fieldValidationErrors = containerData.fields
    ?.filter(field => Some(field.validationErrors))
    .map(field => field.validationErrors ?? [])
    .flat();

  const hasFailures =
    (Some(containerData.activenessFailures) && containerData.activenessFailures.length > 0) ||
    (Some(containerData.completenessFailures) && containerData.completenessFailures.length > 0) ||
    (Some(fieldValidationErrors) && fieldValidationErrors.length > 0);

  const tabs: readonly { readonly key: TabKey; readonly label: ReactNode | string }[] = [
    {
      key: "overview",
      label: "Overview",
    },
    { key: "fields", label: "Fields" },
    {
      key: "failures",
      label: (
        <View style={{ flexDirection: "row" }}>
          <Body
            testProps={{
              elementId: containerData.id,
              elementName: "detailsViewFailureTab",
              screenName: SCREEN_NAME,
            }}
          >
            Failures
          </Body>
          {hasFailures && (
            <>
              <HorizontalSpacer size={theme.spacing.xs} />
              <Icon
                name="warningMediumCritical"
                testProps={{
                  elementId: containerData.id,
                  elementName: "detailsViewFailureTabErrorIcon",
                  screenName: SCREEN_NAME,
                }}
              />
            </>
          )}
        </View>
      ),
    },
  ];

  return (
    <DetailsDrawer
      actionButtons={
        <View style={styles.actionContainer}>
          <View style={{ width: 99 }}>
            <Button
              onPress={() => {
                onRemoveContainer(containerData.id);
              }}
              size="small"
              testProps={{
                elementId: containerData.id,
                elementName: "detailsViewRemoveButton",
                screenName: SCREEN_NAME,
              }}
              text="Remove"
              type="destructive"
            />
          </View>

          <HorizontalSpacer />

          <View style={{ width: 130 }}>
            <Button
              disabled={containerData.completed === false}
              iconLeft={
                containerData.completed === false
                  ? "checkmarkSmallDisabled"
                  : "checkmarkSmallAction"
              }
              onPress={() => {
                onApproveContainer(containerData.id);
              }}
              size="small"
              testProps={{
                elementId: containerData.id,
                elementName: "detailsViewApproveButton",
                screenName: SCREEN_NAME,
              }}
              text="Approve"
              type={containerData.completed === true ? "primary" : "secondary"}
            />
          </View>
        </View>
      }
      onPressClose={() => {
        setActiveTab(tabs[0].key);
        onClose();
      }}
      subTitle={
        <View style={{ flexDirection: "row" }}>
          <Body
            testProps={{
              elementId: containerData.id,
              elementName: "detailsViewContainerID",
              screenName: SCREEN_NAME,
            }}
          >
            Container ID: {containerData.id}
          </Body>
          <HorizontalSpacer />
          <Body color={theme.colors.border.default}>|</Body>
          <HorizontalSpacer />
          <Body
            capitalize
            testProps={{
              elementId: containerData.id,
              elementName: "detailsViewContainerStatus",
              screenName: SCREEN_NAME,
            }}
          >
            Status: {containerData.active === true ? "Active" : "Inactive"}
          </Body>
          <HorizontalSpacer />
          <Body color={theme.colors.border.default}>|</Body>
          <HorizontalSpacer />
          <Body
            capitalize
            testProps={{
              elementId: containerData.id,
              elementName: "detailsViewContainerState",
              screenName: SCREEN_NAME,
            }}
          >
            State: {containerData.state?.name ?? "--"}
          </Body>
        </View>
      }
      testProps={{
        elementId: containerData.id,
        elementName: "detailsView",
        screenName: SCREEN_NAME,
      }}
      title={containerData.name === undefined ? "" : containerData.name}
    >
      <View style={styles.tabsContainer}>
        <Tabs
          items={tabs}
          onChange={tabKey => {
            setActiveTab(tabKey);
          }}
          selected={activeTab}
          testProps={{
            elementId: containerData.id,
            elementName: "detailsView",
            screenName: SCREEN_NAME,
          }}
        />
      </View>

      {activeTab === "overview" && (
        <Overview
          container={containerData}
          testProps={{
            elementId: containerData.id,
            elementName: "detailsViewOverviewTab",
            screenName: SCREEN_NAME,
          }}
        />
      )}
      {activeTab === "fields" && Some(containerData.fields) && (
        <ScrollView>
          <Fields container={containerData} />
        </ScrollView>
      )}
      {activeTab === "failures" && Some(containerData.fields) && (
        <Failures
          activenessFailures={containerData.activenessFailures}
          closeDrawer={() => {
            setActiveTab(tabs[0].key);
            onClose();
          }}
          completenessFailures={containerData.completenessFailures}
          fieldValidationErrors={fieldValidationErrors}
          templateFields={containerData.fields}
          testProps={{
            elementName: "detailsViewFailureTab",
            screenName: SCREEN_NAME,
          }}
        />
      )}
    </DetailsDrawer>
  );
};
